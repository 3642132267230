import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";
import AudioUpload from "../../../AudioUpload";
import ImageUpload from "../../../ImageUpload";
import FileUploadChapters from "./FileUpload";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [PDf, setPDF] = React.useState(FetchData.meta.book_pdf)
  const [ChapterWisePDF, setChapterWisePDF] = React.useState(false)
  const [PDfGroup, setPDFGroup] = React.useState(JSON.parse(FetchData.meta.book_pdf_group))
  const [YoutubeID, setYoutubeID] = React.useState(FetchData.meta.book_youtube_id)
  const [Video, setVideo] = React.useState(FetchData.meta.book_video)
  const [VideoGroup, setVideoGroup] = React.useState(JSON.parse(FetchData.meta.book_video_group))
  const [VideoGroupModal, setVideoGroupModal] = React.useState(false)
  const [Free, setFree] = React.useState(FetchData.meta.free)
  const [Group, setGroup] = React.useState(FetchData.meta.group)
  const [SubGroup, setSubGroup] = React.useState(FetchData.meta.sub_group)
  const [Audio, setAudio] = React.useState(FetchData.meta.book_audio)
  const [AudioGroup, setAudioGroup] = React.useState(JSON.parse(FetchData.meta.book_audio_group))
  const [AudioGroupModal, setAudioGroupModal] = React.useState(false)
  const [OtherInformation, setOtherInformation] = React.useState(JSON.parse(FetchData.meta.book_other_informations))
  const [OtherInformationModal, setOtherInformationModal] = React.useState(false)
  const [Instructions, setInstructions] = React.useState(JSON.parse(FetchData.meta.instructions))
  const [InstructionsModal, setInstructionsModal] = React.useState(false)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "book_name": Title,
        "book_pdf": PDf,
        "book_pdf_group": JSON.stringify(PDfGroup),
        "book_youtube_id": YoutubeID,
        "book_video": Video,
        "book_video_group": JSON.stringify(VideoGroup),
        "free": Free,
        "group": Group,
        "sub_group": SubGroup,
        "book_audio": Audio,
        "book_audio_group": JSON.stringify(AudioGroup),
        "book_other_informations": JSON.stringify(OtherInformation),
        "instructions": JSON.stringify(Instructions),
        "featured_image": FeaturedImage
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_book)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.books_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_book, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined)
      setYoutubeID(url[2].split(/[^0-9a-z_\-]/i)[0])
    // console.log(url[2].split(/[^0-9a-z_\-]/i)[0])
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Book Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  {/* <a href={PDf} target="_blank">{PDf}</a> */}
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Category</td>
                <td className="col-6">
                 <CustomButtonUnSelect setData={setFree} Data={Free} DataValue={"Free"}/>
                </td>
              </tr>
              <tr>
                <td className="col-3">Category</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Group} placeholder="Category"
                    onChange={(event) => {
                      setGroup(event.target.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Sub Category</td>
                <td className="col-6">
                  <input className="input-common"
                    value={SubGroup} placeholder="SubCategory"
                    onChange={(event) => {
                      setSubGroup(event.target.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Book Other Information</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setOtherInformationModal(true)
                    }}
                  >
                    {JSON.stringify(OtherInformation) !== "[]" && "Contains Book Information"}
                    <span className={"passive-option-2"}>
                      Edit Book Information
                    </span>
                  </button>
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Book Instructions</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setInstructionsModal(true)
                    }}
                  >
                    {JSON.stringify(PDfGroup) !== "[]" && "Contains Instructions"}
                    <span className={"passive-option-2"}>
                      Edit Instruction
                    </span>
                  </button>
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Pdf</td>
                <td className="col-6">
                  <a href={PDf} target="_blank">{PDf}</a>
                  <FileUpload
                    ImageUrl={PDf}
                    setImageUrl={setPDF}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Chapter Wise PDF</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setChapterWisePDF(true)
                    }}
                  >
                    {JSON.stringify(PDfGroup) !== "[]" && "Contains Chapter Wise PDF"}
                    <span className={"passive-option-2"}>
                      Edit Chapter PDF
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td className="col-3">Youtube Url</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Video} placeholder="Video Url"
                    onChange={(event) => {
                      setVideo(event.target.value)
                      YouTubeGetID(event.target.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Youtube ID</td>
                <td className="col-6">
                  <input className="input-common"
                    value={YoutubeID} placeholder="Youtube Video ID"
                    onChange={(event) => {
                      setYoutubeID(event.target.value)
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Chapter Wise Video</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setVideoGroupModal(true)
                    }}
                  >
                    {JSON.stringify(VideoGroup) !== "[]" && "Contains Chapter Wise Video"}
                    <span className={"passive-option-2"}>
                      Edit Chapter Video
                    </span>
                  </button>
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio</td>
                <td className="col-6">
                  <AudioUpload
                    ImageUrl={Audio}
                    setImageUrl={setAudio}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Chapter Wise Audio</td>
                <td className="col-6">
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setAudioGroupModal(true)
                    }}
                  >
                    {JSON.stringify(AudioGroup) !== "[]" && "Contains Chapter Wise Audio"}
                    <span className={"passive-option-2"}>
                      Edit Chapter Audio
                    </span>
                  </button>
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Book
              </div>
            </div>
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }

      {ChapterWisePDF &&
        <section className="modal" >
          <ChapterWisePDFUpoadComponent
            setChapterWisePDF={setChapterWisePDF}
            ChapterWisePDF={ChapterWisePDF}
            PDfGroup={PDfGroup}
            setPDFGroup={setPDFGroup}
            type="pdf"
          />
        </section>
      }
      {AudioGroupModal &&
        <section className="modal" >
          <ChapterWisePDFUpoadComponent
            setChapterWisePDF={setAudioGroupModal}
            ChapterWisePDF={AudioGroupModal}
            PDfGroup={AudioGroup}
            setPDFGroup={setAudioGroup}
            type="audio"
          />
        </section>
      }
      {VideoGroupModal &&
        <section className="modal" >
          <ChapterWiseVideoUpoadComponent
            setChapterWisePDF={setVideoGroupModal}
            ChapterWisePDF={VideoGroupModal}
            PDfGroup={VideoGroup}
            setPDFGroup={setVideoGroup}
          />
        </section>
      }
      {OtherInformationModal &&
        <section className="modal" >
          <BookInformationComponent
            setChapterWisePDF={setOtherInformationModal}
            ChapterWisePDF={OtherInformationModal}
            PDfGroup={OtherInformation}
            setPDFGroup={setOtherInformation}
          />
        </section>
      }
    </div>
  )

}

const ChapterWisePDFUpoadComponent = ({
  setChapterWisePDF,
  ChapterWisePDF,
  PDfGroup,
  setPDFGroup,
  type
}) => {
  const [numberofChapters, setNumberofChapters] = React.useState("")

  return (
    <div className="row">
      <div className="col-md-3 col-1"></div>
      <div className="col-md-6 col-10  shadow bg-white">

        <div style={{ border: "1px solid #999", padding: "10px", margin: "10px" }}>
          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
            onClick={() => {
              setChapterWisePDF(false)
            }}
          >X</div>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Number of Chapters</td>
                <td className="col-9 row">
                  <div className=" col-6">
                    <input className="input-common" style={{ width: "100%" }}
                      value={numberofChapters} placeholder="Number of Chapters"
                      onChange={(event) => { setNumberofChapters(event.target.value) }}
                    />
                  </div>
                  <div className=" col-6" style={{ padding: "0 10px" }}>
                    <div className="passive-option-2"
                      onClick={() => {
                        // setQuestionsData
                        var question = {};
                        for (let i = 0; i < numberofChapters; i++) {
                          question = {
                            ...question,
                            [`item-${i}`]: {
                              "Chapter_Number": `${i + 1}`,
                              "title": "",
                              "url": "",
                            }
                          }
                          setPDFGroup(question)
                        }
                      }}
                    >
                      Set Chapters
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ margin: " 10px", overflowX: 'hidden', padding: "9px", border: "1px solid #aaa" }}>
            <div style={{ maxHeight: "300px", }}>
              <table>
                <tbody>
                  {Object.values(PDfGroup).map((item, index) => (
                    <tr key={index}
                      style={{ cursor: "pointer", padding: "5px 0" }}
                    >
                      <td>
                        {item.Chapter_Number}
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.title} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "Chapter_Number": `${item.Chapter_Number}`,
                                "title": `${event.target.value}`,
                                "url": `${item.url}`,
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td>
                        <FileUploadChapters
                          PDfGroup={PDfGroup}
                          setPDFGroup={setPDFGroup}
                          item={item}
                          ThisPDF={item.url}
                          index={index}
                          type={type}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  setChapterWisePDF(false)
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const ChapterWiseVideoUpoadComponent = ({
  setChapterWisePDF,
  ChapterWisePDF,
  PDfGroup,
  setPDFGroup,
}) => {
  const [numberofChapters, setNumberofChapters] = React.useState("")
  const [YoutubeURL, setYoutubeURL] = React.useState("")
  const [YoutubeID, setYoutubeID] = React.useState("")
  function YouTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    // if (url[2] !== undefined)
    //   setYoutubeID(url[2].split(/[^0-9a-z_\-]/i)[0])
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : "";
  }
  return (
    <div className="row">
      <div className="col-md-3 col-1"></div>
      <div className="col-md-6 col-10  shadow bg-white">

        <div style={{ border: "1px solid #999", padding: "10px", margin: "10px" }}>
          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
            onClick={() => {
              setChapterWisePDF(false)
            }}
          >X</div>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Number of Chapters</td>
                <td className="col-9 row">
                  <div className=" col-6">
                    <input className="input-common" style={{ width: "100%" }}
                      value={numberofChapters} placeholder="Number of Chapters"
                      onChange={(event) => { setNumberofChapters(event.target.value) }}
                    />
                  </div>
                  <div className=" col-6" style={{ padding: "0 10px" }}>
                    <div className="passive-option-2"
                      onClick={() => {
                        // setQuestionsData
                        var question = {};
                        for (let i = 0; i < numberofChapters; i++) {
                          question = {
                            ...question,
                            [`item-${i}`]: {
                              "Chapter_Number": `${i + 1}`,
                              "title": "",
                              "url": "",
                            }
                          }
                          setPDFGroup(question)
                        }
                      }}
                    >
                      Set Chapters
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ margin: " 10px", overflowX: 'hidden', padding: "9px", border: "1px solid #aaa" }}>
            <div style={{ maxHeight: "300px", }}>
              <table>
                <tbody>
                  {Object.values(PDfGroup).map((item, index) => (
                    <tr key={index}
                      style={{ cursor: "pointer", padding: "5px 0" }}
                    >
                      <td>
                        {item.Chapter_Number}
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.title} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "Chapter_Number": `${item.Chapter_Number}`,
                                "title": `${event.target.value}`,
                                "url": `${item.url}`,
                                "youtube_id": `${item.youtube_id}`
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.url} placeholder="Video Link"
                          onChange={(event) => {
                            var pdf = PDfGroup;
                            var youtubeID = YouTubeGetID(event.target.value)
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "Chapter_Number": `${item.Chapter_Number}`,
                                "title": `${item.title}`,
                                "url": `${event.target.value}`,
                                "youtube_id": `${youtubeID}`
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.youtube_id} placeholder="Youtube ID"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "Chapter_Number": `${item.Chapter_Number}`,
                                "title": `${item.title}`,
                                "url": `${item.url}`,
                                "youtube_id": `${event.target.value}`
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  setChapterWisePDF(false)
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const BookInformationComponent = ({
  setChapterWisePDF,
  ChapterWisePDF,
  PDfGroup,
  setPDFGroup,
}) => {
  const [numberofChapters, setNumberofChapters] = React.useState("")
  const [Title, setTitle] = React.useState("")
  const [Description, setDescription] = React.useState("")

  return (
    <div className="row">
      <div className="col-md-3 col-1"></div>
      <div className="col-md-6 col-10  shadow bg-white">

        <div style={{ border: "1px solid #999", padding: "10px", margin: "10px" }}>
          <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
            onClick={() => {
              setChapterWisePDF(false)
            }}
          >X</div>
          <div style={{ margin: " 10px", overflowX: 'hidden', padding: "9px", border: "1px solid #aaa" }}>
            <div style={{ maxHeight: "300px", }}>
              <table>
                <tbody>
                  {Object.values(PDfGroup).map((item, index) => (
                    <tr key={index}
                      style={{ cursor: "pointer", padding: "5px 0" }}
                    >
                      <td>
                        {index + 1}
                      </td>
                      <td>
                        <input className="input-common" style={{ width: "100%" }}
                          value={item.title} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "title": `${event.target.value}`,
                                "description": `${item.description}`,
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td>
                        <textarea rows={3} className="input-common" style={{ width: "100%" }}
                          value={item.description} placeholder="Title"
                          onChange={(event) => {
                            // setNumberofChapters(event.target.value)
                            var pdf = PDfGroup;
                            pdf = {
                              ...pdf,
                              [`item-${index}`]: {
                                "description": `${event.target.value}`,
                                "title": `${item.title}`,
                              }
                            }
                            setPDFGroup(pdf)
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
                  <tr style={{ cursor: "pointer", padding: "5px 0" }}>
                    <td>{Object.keys(PDfGroup).length+1}</td>
                    <td>
                      <input className="input-common" style={{ width: "100%" }}
                        value={Title} placeholder="Title"
                        onChange={(event) => {
                          setTitle(event.target.value)
                        }}
                      />
                    </td>
                    <td>
                      <textarea rows={3} className="input-common" style={{ width: "100%" }}
                        value={Description} placeholder="Description"
                        onChange={(event) => {
                          setDescription(event.target.value)
                        }}
                      />
                    </td>
                    <td
                    onClick={()=>{
                       // setNumberofChapters(event.target.value)
                       var pdf = PDfGroup;
                       pdf = {
                         ...pdf,
                         [`item-${Object.keys(PDfGroup).length}`]: {
                           "title": `${Title}`,
                           "description": `${Description}`,
                         }
                       }
                       setPDFGroup(pdf)
                       setTitle("")
                       setDescription("")
                    }}
                    >Add</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  setChapterWisePDF(false)
                }}
              >
                Done
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}